import { FC } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { Badge } from 'dibs-elements/exports/Badge';
import classnames from 'classnames';

import dibsCss from 'dibs-css';

import { StorefrontOnly_item$data } from './__generated__/StorefrontOnly_item.graphql';

type Props = {
    item: StorefrontOnly_item$data;
    isItemTileShorterHeight?: boolean;
};

const StorefrontOnlyBadge: FC<Props> = ({ item, isItemTileShorterHeight }) => {
    if (!item?.isStorefrontOnly) {
        return null;
    }

    return (
        <div
            className={classnames({
                [dibsCss.mbXxsmall]: isItemTileShorterHeight,
                [dibsCss.mbXsmall]: !isItemTileShorterHeight,
            })}
            data-tn="storefront-only"
        >
            <Badge type="pill" size="small-heavy">
                <FormattedMessage
                    id="dc.searchProductTile.storefrontOnly"
                    defaultMessage="Storefront Only"
                />
            </Badge>
        </div>
    );
};

export default createFragmentContainer(StorefrontOnlyBadge, {
    item: graphql`
        fragment StorefrontOnly_item on Item {
            isStorefrontOnly
        }
    `,
});
