/**
 * @generated SignedSource<<9845e147845782f7f301604678f732a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "CHF" | "DKK" | "EUR" | "GBP" | "JPY" | "MXN" | "NOK" | "SEK" | "USD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useEligibleSecondChanceOfferState_item$data = {
  readonly auctionSecondChanceInfo: {
    readonly eligibleAuctionLot: {
      readonly buyItNowPrice: {
        readonly convertedAmountList: ReadonlyArray<{
          readonly amount: number | null;
          readonly currency: Currency | null;
        } | null> | null;
      } | null;
      readonly reservePrice: {
        readonly convertedAmountList: ReadonlyArray<{
          readonly amount: number | null;
          readonly currency: Currency | null;
        } | null> | null;
      } | null;
    } | null;
    readonly eligibleAuctionSolution: {
      readonly bids: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly amount: {
              readonly convertedAmountList: ReadonlyArray<{
                readonly amount: number | null;
                readonly currency: Currency | null;
              } | null> | null;
            } | null;
          } | null;
        } | null> | null;
        readonly totalResults: number | null;
      } | null;
      readonly highestBidAmount: {
        readonly convertedAmountList: ReadonlyArray<{
          readonly amount: number | null;
          readonly currency: Currency | null;
        } | null> | null;
      } | null;
      readonly reserveMet: boolean | null;
      readonly totalUniqueBidders: number | null;
    } | null;
  } | null;
  readonly " $fragmentType": "useEligibleSecondChanceOfferState_item";
};
export type useEligibleSecondChanceOfferState_item$key = {
  readonly " $data"?: useEligibleSecondChanceOfferState_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"useEligibleSecondChanceOfferState_item">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ConvertedAmountList",
    "kind": "LinkedField",
    "name": "convertedAmountList",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useEligibleSecondChanceOfferState_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AuctionSecondChanceInfo",
      "kind": "LinkedField",
      "name": "auctionSecondChanceInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AuctionLot",
          "kind": "LinkedField",
          "name": "eligibleAuctionLot",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "FinancialAmount",
              "kind": "LinkedField",
              "name": "buyItNowPrice",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "FinancialAmount",
              "kind": "LinkedField",
              "name": "reservePrice",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AuctionSolution",
          "kind": "LinkedField",
          "name": "eligibleAuctionSolution",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "reserveMet",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalUniqueBidders",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AuctionBidsConnection",
              "kind": "LinkedField",
              "name": "bids",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "totalResults",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AuctionBidsEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AuctionBid",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "FinancialAmount",
                          "kind": "LinkedField",
                          "name": "amount",
                          "plural": false,
                          "selections": (v0/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "FinancialAmount",
              "kind": "LinkedField",
              "name": "highestBidAmount",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};
})();

(node as any).hash = "4903dd7b908e0d028c2ba98e5d3baed0";

export default node;
