import { useFragment, graphql } from 'react-relay';
import { getAmountInCurrency } from '../helpers/getAmountInCurrency';

import type { ConvertedAmount } from './types/ConvertedAmount';
import type { useDraftAuctionPricing_item$key as Item } from './__generated__/useDraftAuctionPricing_item.graphql';

const itemFragment = graphql`
    fragment useDraftAuctionPricing_item on Item {
        draftAuctionLot {
            openingBid {
                convertedAmountList {
                    amount
                    currency
                }
            }
            reservePrice {
                convertedAmountList {
                    amount
                    currency
                }
            }
            buyItNowPrice {
                convertedAmountList {
                    amount
                    currency
                }
            }
        }
    }
`;

export type ReturnType = {
    startingBidAmount: ConvertedAmount;
    reservePriceAmount: ConvertedAmount;
    buyItNowAmount: ConvertedAmount;
};

// should be used only in admin/seller apps
export const useDraftAuctionPricing = ({
    currency,
    item: itemRef,
}: {
    item: Item | null | undefined;
    currency: string;
}): ReturnType => {
    const item = useFragment(itemFragment, itemRef);
    const { draftAuctionLot } = item || {};
    const { openingBid, reservePrice, buyItNowPrice } = draftAuctionLot || {};

    return {
        startingBidAmount: getAmountInCurrency(openingBid?.convertedAmountList || [], currency),
        reservePriceAmount: getAmountInCurrency(reservePrice?.convertedAmountList || [], currency),
        buyItNowAmount: getAmountInCurrency(buyItNowPrice?.convertedAmountList || [], currency),
    };
};
