/**
 * @generated SignedSource<<e8d9ddaba576e2ec1b24ecf09151b713>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "CHF" | "DKK" | "EUR" | "GBP" | "JPY" | "MXN" | "NOK" | "SEK" | "USD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useDraftAuctionPricing_item$data = {
  readonly draftAuctionLot: {
    readonly buyItNowPrice: {
      readonly convertedAmountList: ReadonlyArray<{
        readonly amount: number | null;
        readonly currency: Currency | null;
      } | null> | null;
    } | null;
    readonly openingBid: {
      readonly convertedAmountList: ReadonlyArray<{
        readonly amount: number | null;
        readonly currency: Currency | null;
      } | null> | null;
    } | null;
    readonly reservePrice: {
      readonly convertedAmountList: ReadonlyArray<{
        readonly amount: number | null;
        readonly currency: Currency | null;
      } | null> | null;
    } | null;
  } | null;
  readonly " $fragmentType": "useDraftAuctionPricing_item";
};
export type useDraftAuctionPricing_item$key = {
  readonly " $data"?: useDraftAuctionPricing_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"useDraftAuctionPricing_item">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ConvertedAmountList",
    "kind": "LinkedField",
    "name": "convertedAmountList",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useDraftAuctionPricing_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AuctionLot",
      "kind": "LinkedField",
      "name": "draftAuctionLot",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FinancialAmount",
          "kind": "LinkedField",
          "name": "openingBid",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FinancialAmount",
          "kind": "LinkedField",
          "name": "reservePrice",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FinancialAmount",
          "kind": "LinkedField",
          "name": "buyItNowPrice",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};
})();

(node as any).hash = "aa78781eeaa543e6346ca4142cc891eb";

export default node;
