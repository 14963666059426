const REFRESH_RATE_HOUR = 3600000; // 1000ms * 60s * 60m
const REFRESH_RATE_MINUTE = 60000; // 1000ms * 60s
const REFRESH_RATE_SECOND = 1000; // 1000ms * 1s

const THRESHOLD_TWO_HOURS = 7200; // REFRESH_RATE_HOUR * 2 / 1000
const THRESHOLD_ONE_HOURS = 3600; // REFRESH_RATE_HOUR / 1000
const THRESHOLD_TWO_MINUTES = 120; // REFRESH_RATE_MINUTE * 2 / 1000

/**
 * @desc Returns refresh rate for *setTimeout()* and handles cases where the timer is not synced with clock time.
 
    If for example there are 3h 7m remaining until the end of an auction
    and *setTimeout* gets called, it will then fire again at: 
    - 2h 7m
    - 1h 7m
    - 0h 7m

    The last one is not ideal, so if we are anywhere between 2h and 1h (in this case we are at 1h 7m)
    we start refreshing every 5 minutes (REFRESH_RATE_MINUTE * 5). Then the case above would look like:
    - 2h 7m
    - 1h 7m
    - 1h 2m
    - 57m

    The same logic applies for minutes.
 */
export const getRefreshRate = (secondsRemaining: number): number | null => {
    if (!secondsRemaining) {
        return null;
    }

    if (secondsRemaining > THRESHOLD_TWO_HOURS) {
        return REFRESH_RATE_HOUR;
    } else if (secondsRemaining > THRESHOLD_ONE_HOURS) {
        // 5 minutes is an arbitrary value, but it's a good compromise between performance and accuracy
        // At worse we miss displaying minutes between 1h and 55m
        return REFRESH_RATE_MINUTE * 5;
    } else if (secondsRemaining > THRESHOLD_TWO_MINUTES) {
        return REFRESH_RATE_MINUTE;
    }

    return REFRESH_RATE_SECOND;
};
