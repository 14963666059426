import { useFragment, graphql } from 'react-relay';

import { useOriginalListPrice_item$key } from './__generated__/useOriginalListPrice_item.graphql';

const itemFragment = graphql`
    fragment useOriginalListPrice_item on Item
    @argumentDefinitions(page: { type: "PageDisplayEnum", defaultValue: pdp }) {
        originalDisplayPrice: displayPrice(page: $page) {
            originalConvertedAmountList {
                amount
                currency
            }
        }
        pricing {
            retailPrice {
                convertedAmountList {
                    amount
                    currency
                }
            }
        }
    }
`;

type Props = {
    item: useOriginalListPrice_item$key | null | undefined;
    currency: string | null;
};

type Output = {
    originalListPrice: number | null;
    hasOriginalListPrice: boolean;
};

export const useOriginalListPrice = ({ item: itemRef, currency }: Props): Output => {
    const item = useFragment(itemFragment, itemRef);
    const retailPrice = item?.pricing?.retailPrice;

    const originalConvertedAmountList =
        item?.originalDisplayPrice?.[0]?.originalConvertedAmountList ||
        retailPrice?.convertedAmountList;
    const originalListPriceByCurrency = originalConvertedAmountList?.find(
        convertedAmount => convertedAmount?.currency === currency
    );

    let originalListPrice = null;
    if (originalListPriceByCurrency?.amount) {
        originalListPrice = Math.ceil(originalListPriceByCurrency.amount);
    }

    return {
        originalListPrice,
        hasOriginalListPrice: !!originalListPrice,
    };
};
