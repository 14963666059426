/**
 * @generated SignedSource<<019bd845341d8fc60117fcef74e29d97>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "CHF" | "DKK" | "EUR" | "GBP" | "JPY" | "MXN" | "NOK" | "SEK" | "USD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useAuctionItemPriceDetails_item$data = {
  readonly pricing: {
    readonly totalAmount: {
      readonly currency: Currency | null;
    } | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"useAuctionPricing_item" | "useDraftAuctionPricing_item" | "useScheduledAuctionPricing_item">;
  readonly " $fragmentType": "useAuctionItemPriceDetails_item";
};
export type useAuctionItemPriceDetails_item$key = {
  readonly " $data"?: useAuctionItemPriceDetails_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"useAuctionItemPriceDetails_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "page"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useAuctionItemPriceDetails_item",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useScheduledAuctionPricing_item"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "page",
          "variableName": "page"
        }
      ],
      "kind": "FragmentSpread",
      "name": "useAuctionPricing_item"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useDraftAuctionPricing_item"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ItemPricing",
      "kind": "LinkedField",
      "name": "pricing",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FinancialAmount",
          "kind": "LinkedField",
          "name": "totalAmount",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currency",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "4bf452ce0cb74e8bbf6437c74fb47d63";

export default node;
