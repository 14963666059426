import { FC, lazy } from 'react';
import { ClientSuspense } from 'dibs-elements/exports/ClientSuspense';

import styles from './styles.scss';

const PaddleIcon = lazy(
    () => import(/* webpackChunkName: "PaddleIcon" */ 'dibs-icons/exports/legacy/Paddle')
);

export const PricePaddleIcon: FC = () => {
    return (
        <div className={styles.paddleContainer}>
            <ClientSuspense fallback={null}>
                <PaddleIcon className={styles.paddle} />
            </ClientSuspense>
        </div>
    );
};
