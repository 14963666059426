import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';

import { Product } from '../Product';
import { ItemTileBase } from './ItemTileBase';

export const ItemTileComponent = ({ item, itemSearch, ...props }) => {
    return (
        <ItemTileBase item={item} itemSearch={itemSearch} {...props}>
            {productProps => <Product {...productProps} item={item} />}
        </ItemTileBase>
    );
};

ItemTileComponent.propTypes = {
    item: PropTypes.object,
    itemSearch: PropTypes.object,
};

export const ItemTile = createFragmentContainer(ItemTileComponent, {
    viewer: graphql`
        fragment ItemTile_viewer on Viewer
        @argumentDefinitions(
            fetchRegionalInfo: { type: "Boolean", defaultValue: false }
            userZipCode: { type: "String", defaultValue: "" }
            userCountryCode: { type: "String", defaultValue: "" }
        ) {
            ...ItemTileBase_viewer
                @arguments(
                    fetchRegionalInfo: $fetchRegionalInfo
                    userZipCode: $userZipCode
                    userCountryCode: $userCountryCode
                )
        }
    `,
    itemSearch: graphql`
        fragment ItemTile_itemSearch on ItemSearchQueryConnection {
            ...ItemTileBase_itemSearch
        }
    `,
    item: graphql`
        fragment ItemTile_item on Item
        @argumentDefinitions(
            isTrade: { type: "Boolean", defaultValue: false }
            showSeller: { type: "Boolean", defaultValue: false }
            pageDisplayEnum: { type: "PageDisplayEnum", defaultValue: searchAndBrowse }
            buyerId: { type: "String", defaultValue: "" }
            fetchShippingCostInfo: { type: "Boolean", defaultValue: false }
            fetchLiveShipmentQuote: { type: "Boolean", defaultValue: true }
            showShippingBadge: { type: "Boolean", defaultValue: true }
            showAuctionCountdown: { type: "Boolean", defaultValue: true }
            priceBookName: { type: "String" }
        ) {
            ...ItemTileBase_item
                @arguments(
                    showSeller: $showSeller
                    isTrade: $isTrade
                    pageDisplayEnum: $pageDisplayEnum
                    buyerId: $buyerId
                    fetchShippingCostInfo: $fetchShippingCostInfo
                    fetchLiveShipmentQuote: $fetchLiveShipmentQuote
                    showShippingBadge: $showShippingBadge
                    priceBookName: $priceBookName
                )
            ...Product_item
                @arguments(
                    pageDisplayEnum: $pageDisplayEnum
                    showAuctionCountdown: $showAuctionCountdown
                )
        }
    `,
});
