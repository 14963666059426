import { useAuctionPricing, useDraftAuctionPricing } from 'dibs-auctions/exports/hooks';
import { AuctionPricing } from 'dibs-auctions/exports/hooks/useAuctionPricing';
import {
    ScheduledAuctionPricing,
    useScheduledAuctionPricing,
} from 'dibs-auctions/exports/hooks/useScheduledAuctionPricing';
import { IntlShape, useIntl } from 'dibs-react-intl';
import { graphql, useFragment } from 'react-relay';

import { useAuctionItemPriceDetails_item$key } from './__generated__/useAuctionItemPriceDetails_item.graphql';

type FormatPriceArgs = {
    amount?: number | null;
    currency?: string | null;
    intl: IntlShape;
};

const formatPrice = ({ amount, currency, intl }: FormatPriceArgs): string => {
    if (!amount || amount < 0.01) {
        return 'N/A';
    } else {
        return intl.formatNumber(amount, {
            style: 'currency',
            currency: currency || 'USD',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
        });
    }
};

const itemFragment = graphql`
    fragment useAuctionItemPriceDetails_item on Item
    @argumentDefinitions(page: { type: "PageDisplayEnum", defaultValue: null }) {
        ...useScheduledAuctionPricing_item
        ...useAuctionPricing_item @arguments(page: $page)
        ...useDraftAuctionPricing_item
        pricing {
            totalAmount {
                currency
            }
        }
    }
`;

type Props = {
    item: useAuctionItemPriceDetails_item$key | null | undefined;
    currency?: string;
};

type ReturnValueKeys =
    | 'reservePriceAmount'
    | 'startingBidAmount'
    | 'buyItNowAmount'
    | 'currentBidAmount';

type ReturnType = Record<
    ReturnValueKeys,
    {
        value: number | null;
        formatted: string;
    }
>;

export const useAuctionItemPriceDetails = ({ item: itemRef, currency }: Props): ReturnType => {
    const intl = useIntl();
    const item = useFragment(itemFragment, itemRef);
    const { pricing } = item || {};
    const amountCurrency = currency || pricing?.totalAmount?.currency || 'USD';
    let auctionLotPriceInfo: ScheduledAuctionPricing | AuctionPricing | null = null;
    const relevantAuctionLotPriceInfo = useAuctionPricing({
        item,
        currency: amountCurrency,
    });
    const scheduledAuctionLotPriceInfo = useScheduledAuctionPricing({
        item,
        currency: amountCurrency,
    });
    const {
        startingBidAmount: draftStartingBidAmount,
        reservePriceAmount: draftReservePriceAmount,
        buyItNowAmount: draftBuyItNowAmount,
    } = useDraftAuctionPricing({
        item,
        currency: amountCurrency,
    });

    if (scheduledAuctionLotPriceInfo) {
        auctionLotPriceInfo = scheduledAuctionLotPriceInfo;
    } else {
        auctionLotPriceInfo = relevantAuctionLotPriceInfo;
    }

    const {
        currentBidAmount,
        startingBidAmount: auctionLotStartingBidAmount,
        reservePriceAmount: auctionLotReservePriceAmount,
        buyItNowAmount: auctionLotBuyItNowAmount,
    } = auctionLotPriceInfo;

    const showRelevantAuctionLotData = !!auctionLotStartingBidAmount;
    const startingBidAmount = showRelevantAuctionLotData
        ? auctionLotStartingBidAmount
        : draftStartingBidAmount;
    const reservePriceAmount = showRelevantAuctionLotData
        ? auctionLotReservePriceAmount
        : draftReservePriceAmount;
    const buyItNowAmount = auctionLotBuyItNowAmount || draftBuyItNowAmount;

    const formattedHighestBidPrice = formatPrice({
        amount: currentBidAmount,
        currency: amountCurrency,
        intl,
    });
    const formattedStartingPrice = formatPrice({
        amount: startingBidAmount,
        currency: amountCurrency,
        intl,
    });
    const formattedReservePrice = formatPrice({
        amount: reservePriceAmount,
        currency: amountCurrency,
        intl,
    });
    const formattedBuyItNowPrice = formatPrice({
        amount: buyItNowAmount,
        currency: amountCurrency,
        intl,
    });

    return {
        reservePriceAmount: {
            value: reservePriceAmount,
            formatted: formattedReservePrice,
        },
        startingBidAmount: {
            value: startingBidAmount,
            formatted: formattedStartingPrice,
        },
        buyItNowAmount: {
            value: buyItNowAmount,
            formatted: formattedBuyItNowPrice,
        },
        currentBidAmount: {
            value: currentBidAmount,
            formatted: formattedHighestBidPrice,
        },
    };
};
