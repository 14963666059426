import { ClientSuspense } from 'dibs-elements/exports/ClientSuspense';
import { FC, lazy } from 'react';
import { graphql, useFragment } from 'react-relay';
import { OriginalListPriceProps } from './OriginalListPrice';

import { OriginalListPriceLazy_item$key as Item } from './__generated__/OriginalListPriceLazy_item.graphql';

const OriginalListPrice = lazy(
    () => import(/* webpackChunkName: "OriginalListPrice" */ './OriginalListPrice')
);

const itemFragment = graphql`
    fragment OriginalListPriceLazy_item on Item
    @argumentDefinitions(page: { type: "PageDisplayEnum", defaultValue: pdp }) {
        ...OriginalListPrice_item @arguments(page: $page)
    }
`;

type Props = {
    item: Item;
} & Omit<OriginalListPriceProps, 'item'>;

const OriginalListPriceLazy: FC<Props> = ({ item: itemRef, ...props }) => {
    const item = useFragment(itemFragment, itemRef);

    return (
        <ClientSuspense fallback={null}>
            <OriginalListPrice item={item} {...props} />
        </ClientSuspense>
    );
};

export default OriginalListPriceLazy;
