import { useFragment, graphql } from 'react-relay';
import { AUCTIONS_LOT_STATUS } from '../constants';
import { getAmountInCurrency } from '../helpers/getAmountInCurrency';

import type { ConvertedAmount } from './types/ConvertedAmount';
import type { useScheduledAuctionPricing_item$key as Item } from './__generated__/useScheduledAuctionPricing_item.graphql';

const itemFragment = graphql`
    fragment useScheduledAuctionPricing_item on Item {
        scheduledAuctionLot {
            status
            buyItNowPrice {
                convertedAmountList {
                    amount
                    currency
                }
            }
            openingBid {
                convertedAmountList {
                    amount
                    currency
                }
            }
            reservePrice {
                convertedAmountList {
                    amount
                    currency
                }
            }
        }
        isSold
    }
`;

export type ReturnType = {
    totalBids: number;
    reserveMet: boolean;
    hasReserve: boolean;
    isBuyItNowEligible: boolean;
    buyItNowAmount: ConvertedAmount;
    startingBidAmount: ConvertedAmount;
    currentBidAmount: ConvertedAmount;
    reservePriceAmount: ConvertedAmount;
};

export const useScheduledAuctionPricing = ({
    currency,
    item: itemRef,
}: {
    item: Item | null | undefined;
    currency: string;
}): ReturnType | null => {
    const item = useFragment(itemFragment, itemRef);
    const { scheduledAuctionLot, isSold } = item || {};
    const isScheduledAuctionLot =
        scheduledAuctionLot?.status === AUCTIONS_LOT_STATUS.SCHEDULED && !isSold;

    if (!isScheduledAuctionLot) {
        return null;
    }

    const {
        buyItNowPrice: scheduledBuyItNowPrice,
        openingBid: scheduledOpeningBid,
        reservePrice: scheduledReservePrice,
    } = scheduledAuctionLot || {};

    return {
        totalBids: 0,
        reserveMet: false,
        hasReserve: !!scheduledReservePrice,
        isBuyItNowEligible: true,
        buyItNowAmount: getAmountInCurrency(
            scheduledBuyItNowPrice?.convertedAmountList || [],
            currency
        ),
        startingBidAmount: getAmountInCurrency(
            scheduledOpeningBid?.convertedAmountList || [],
            currency
        ),
        currentBidAmount: 0,
        reservePriceAmount: getAmountInCurrency(
            scheduledReservePrice?.convertedAmountList || [],
            currency
        ),
    };
};
