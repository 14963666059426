import { FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import { FormattedMessage, useIntl } from 'dibs-react-intl';
import { useCountdown } from 'dibs-elements/exports/CountdownTimer';
import classnames from 'classnames';
import { useAuctionState } from 'dibs-auctions/exports/hooks/useAuctionState';
import { getCalendarDaysUntilEnd } from 'dibs-auctions/exports/helpers/getCalendarDaysUntilEnd';
import { getRefreshRate } from '../../helpers/getRefreshRate';
import { useClientState } from 'dibs-react-hooks/exports/useClientState';

import { AuctionCountdown_item$key } from './__generated__/AuctionCountdown_item.graphql';

import styles from './styles.scss';

const itemFragment = graphql`
    fragment AuctionCountdown_item on Item @argumentDefinitions(page: { type: PageDisplayEnum }) {
        relevantAuctionLot(page: $page) {
            startDate
            endDate
        }
        ...useAuctionState_item @arguments(page: $page)
    }
`;

export const AuctionCountdown: FC<{ item: AuctionCountdown_item$key; isCompact?: boolean }> = ({
    item: itemRef,
    isCompact = false,
}) => {
    const intl = useIntl();
    const item = useFragment(itemFragment, itemRef);
    const isClient = useClientState();
    const {
        isActiveAuction,
        isPreviewAuction,
        hasAuctionEndedWithWinner,
        hasAuctionEndedWithNoWinner,
        isPausedAuction,
        seconds: secondsRemaining,
    } = useAuctionState({ item });
    const refreshRate = getRefreshRate(secondsRemaining);
    const { relevantAuctionLot } = item || {};
    const startDate = relevantAuctionLot?.startDate;
    const endDate = relevantAuctionLot?.endDate || '';
    const { countdownUnits, countdownFinished } = useCountdown({
        targetDate: isPreviewAuction ? startDate : endDate,
        loadOnFirstRender: true,
        interval: refreshRate,
    });
    if (!startDate || !countdownUnits || !isClient) {
        return null;
    }

    const { days, hours, minutes, seconds } = countdownUnits;
    const isLessThanHour = days === 0 && hours === 0 && minutes > 0;
    const isLessThanAMinute = days === 0 && hours === 0 && minutes === 0 && seconds > 0;

    if (isPreviewAuction) {
        if (!isCompact) {
            const monthLong = intl.formatDate(startDate, { month: 'long' });
            const monthShort = intl.formatDate(startDate, { month: 'short' });
            const day = intl.formatDate(startDate, { day: 'numeric' });
            const time = intl.formatTime(startDate, {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true,
                timeZoneName: 'short',
            });
            const isAbbreviated = monthLong !== monthShort;

            return (
                <div className={styles.wrapper} data-tn="auction-preview">
                    <FormattedMessage
                        id="da.auctionCountdown.resp.liveAuction"
                        defaultMessage="Auction Starts {isAbbreviated, select,
                            true {{month}.}
                            other {{month}}
                        } {day}, {time}"
                        values={{
                            isAbbreviated,
                            month: monthShort,
                            day,
                            time,
                        }}
                    />
                </div>
            );
        }

        if (isLessThanHour) {
            return (
                <div className={styles.wrapper} data-tn="auction-preview-minutes">
                    <FormattedMessage
                        id="da.auctionCountdown.mobile.startsInLessThanHour"
                        defaultMessage="Starts in <1 hour"
                    />
                </div>
            );
        }

        return (
            <div
                className={styles.wrapper}
                data-tn={`auction-preview-less-${days ? 'days' : 'hours'}`}
            >
                {days ? (
                    <FormattedMessage
                        id="da.auctionCountdown.mobile.startsInDays"
                        defaultMessage="Starts in {days} {days, plural, one {day} other {days}}"
                        values={{ days }}
                    />
                ) : (
                    <FormattedMessage
                        id="da.auctionCountdown.mobile.startsInHours"
                        defaultMessage="Starts in {hours} {hours, plural, one {hour} other {hours}}"
                        values={{ hours }}
                    />
                )}
            </div>
        );
    } else if (isActiveAuction && !countdownFinished) {
        if (days) {
            const daysUntilEnd = getCalendarDaysUntilEnd(endDate);
            return (
                <div className={styles.wrapper} data-tn="auction-ends-days">
                    {isCompact ? (
                        <FormattedMessage
                            id="da.auctionCountdown.mobile.endsInDays"
                            defaultMessage="Ends in {days} {days, plural, one {day} other {days}}"
                            values={{ days: daysUntilEnd }}
                        />
                    ) : (
                        <FormattedMessage
                            id="da.auctionCountdown.resp.endsInDays"
                            defaultMessage="Auction Ends in {days} {days, plural, one {day} other {days}}"
                            values={{ days: daysUntilEnd }}
                        />
                    )}
                </div>
            );
        }

        if (isLessThanHour) {
            return (
                <div
                    className={classnames(styles.wrapper, styles.endingSoon)}
                    data-tn="auction-ends-minutes"
                >
                    {isCompact ? (
                        <FormattedMessage
                            id="da.auctionCountdown.mobile.endsInLessThanHour"
                            defaultMessage="Ends in {minutes} min"
                            values={{ minutes }}
                        />
                    ) : (
                        <FormattedMessage
                            id="da.auctionCountdown.resp.endsInLessThanHour"
                            defaultMessage="Auction Ends in {minutes, plural, one {1 minute} other {{minutes} minutes}}"
                            values={{ minutes }}
                        />
                    )}
                </div>
            );
        } else if (isLessThanAMinute) {
            return (
                <div
                    className={classnames(styles.wrapper, styles.endingSoon)}
                    data-tn="auction-ends-minute"
                >
                    {isCompact ? (
                        <FormattedMessage
                            id="da.auctionCountdown.mobile.endsInLessThanAMinute"
                            defaultMessage="Ends in {seconds, plural, one {1 second} other {{seconds} seconds}}"
                            values={{ seconds }}
                        />
                    ) : (
                        <FormattedMessage
                            id="da.auctionCountdown.resp.endsInLessThanAMinute"
                            defaultMessage="Auction Ends in {seconds, plural, one {1 second} other {{seconds} seconds}}"
                            values={{ seconds }}
                        />
                    )}
                </div>
            );
        }

        return (
            <div
                className={classnames(styles.wrapper, styles.endingSoon)}
                data-tn="auction-ends-hours"
            >
                {isCompact ? (
                    <FormattedMessage
                        id="da.auctionCountdown.mobile.endsInHours"
                        defaultMessage="Ends in {hours} {hours, plural, one {hour} other {hours}}"
                        values={{ hours }}
                    />
                ) : (
                    <FormattedMessage
                        id="da.auctionCountdown.resp.endsInHours"
                        defaultMessage="Auction Ends in {hours} {hours, plural, one {hour} other {hours}}"
                        values={{ hours }}
                    />
                )}
            </div>
        );
    } else if (hasAuctionEndedWithWinner) {
        return (
            <div className={styles.wrapper} data-tn="auction-ended">
                <FormattedMessage
                    id="da.auctionCountdown.shared.auctionEnded"
                    defaultMessage="Auction Ended"
                />
            </div>
        );
    } else if (hasAuctionEndedWithNoWinner) {
        return null;
    } else if (isPausedAuction) {
        return (
            <div className={styles.wrapper} data-tn="pdp-bidding-closed">
                <FormattedMessage
                    id="da.auctionCountdown.shared.biddingClosed"
                    defaultMessage="Bidding Closed"
                />
            </div>
        );
    }

    //handles unknown and unavailable states
    return null;
};
