function getStartOfDay(date: Date): Date {
    return new Date(date.setHours(0, 0, 0, 0));
}

const ONE_DAY_MS = 1000 * 60 * 60 * 24;

export function getCalendarDaysUntilEnd(endDate: string): number {
    const now = getStartOfDay(new Date());
    const end = getStartOfDay(new Date(endDate));
    const days = (end.getTime() - now.getTime()) / ONE_DAY_MS;
    return Math.floor(Math.abs(days));
}
