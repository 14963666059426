import { FC, useEffect, useState, ReactChild } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import classnames from 'classnames';

// styles
import styles from './main.scss';

export const MS_PER_DAY = 86400000 as const; // 1000ms * 60s * 60m * 24h

const units = {
    days: 86400,
    hours: 3600,
    minutes: 60,
    seconds: 1,
};

type UnitMap = typeof units;

export function withinWarningThreshold(
    endDate: string | null | undefined,
    warningThresholdMS?: number
): boolean {
    if (!endDate || !warningThresholdMS) {
        return false;
    }

    const difference = new Date(endDate).getTime() - Date.now();
    return difference <= warningThresholdMS;
}

export function calculateCountdown(endDate: string | null | undefined): UnitMap | null {
    if (!endDate) {
        return null;
    }
    let difference = Math.round((Date.parse(endDate) - Date.now()) / 1000);
    difference = Math.max(difference, 0);

    const output: UnitMap = {} as UnitMap;

    for (const [key, amount] of Object.entries(units)) {
        const fullUnits = Math.floor(difference / amount);

        output[key as keyof UnitMap] = fullUnits;

        difference -= fullUnits * amount;
    }

    return output;
}

type UseCountdownProps = {
    targetDate: string | null | undefined;
    loadOnFirstRender?: boolean;
    interval?: number | null;
};

export function useCountdown({ targetDate, loadOnFirstRender, interval }: UseCountdownProps): {
    countdownUnits: UnitMap | null;
    countdownFinished: boolean | null;
} {
    const [unitMap, setUnitMap] = useState<UnitMap | null>(
        loadOnFirstRender ? calculateCountdown(targetDate) : null
    );
    const [countdownFinished, setCountdownFinished] = useState(false);
    useEffect(() => {
        let id: NodeJS.Timeout;
        if (targetDate && !countdownFinished) {
            id = setInterval(() => setUnitMap(calculateCountdown(targetDate)), interval || 1000);
        }
        return () => {
            clearInterval(id);
        };
    }, [targetDate, countdownFinished, interval]);

    if (!countdownFinished && unitMap && Object.values(unitMap).every(value => value === 0)) {
        setCountdownFinished(true);
    }

    return {
        countdownUnits: unitMap,
        countdownFinished,
    };
}

export type Props = {
    targetDate: string;
    preview?: boolean;
    countdownText?: boolean;
    maxUnits?: number;
    className?: string;
    loadOnFirstRender?: boolean;
    alignLabels?: 'right' | 'bottom';
    countdownFinishedLabel?: ReactChild;
    shouldReturnText?: boolean;
    highlightEndingTimer?: boolean;
    warningThresholdMS?: number;
    onCountdownFinished?: () => void;
};

export const CountdownTimer: FC<Props> = ({
    targetDate,
    preview,
    countdownText,
    className,
    maxUnits = 4,
    loadOnFirstRender,
    alignLabels = 'bottom',
    onCountdownFinished,
    countdownFinishedLabel = null,
    shouldReturnText = false,
    warningThresholdMS = 0,
}) => {
    const { countdownUnits, countdownFinished } = useCountdown({
        targetDate,
        loadOnFirstRender,
    });

    useEffect(() => {
        if (countdownFinished && typeof onCountdownFinished === 'function') {
            onCountdownFinished();
        }
    }, [countdownFinished, onCountdownFinished]);

    if (!countdownUnits || countdownFinished) {
        return countdownFinished && countdownFinishedLabel ? <>{countdownFinishedLabel}</> : null;
    }

    const isRightAlignedLabel = alignLabels === 'right';
    const unitsToShow: (keyof UnitMap)[] = [];

    Object.keys(countdownUnits).forEach(unit => {
        if (
            unitsToShow.length < maxUnits &&
            (countdownUnits[unit as keyof UnitMap] > 0 || unitsToShow.length > 0)
        ) {
            unitsToShow.push(unit as keyof UnitMap);
        }
    });

    if (shouldReturnText) {
        const withinThreshold = withinWarningThreshold(targetDate, warningThresholdMS);
        const intlKeys: {
            [key in keyof UnitMap]: number | null;
        } = {
            days: null,
            hours: null,
            minutes: null,
            seconds: null,
        };
        unitsToShow.forEach(unitLabel => {
            intlKeys[unitLabel] = countdownUnits[unitLabel];
        });

        let timer = (
            <FormattedMessage
                id="countdownTimer.textOutput"
                defaultMessage="{days, select, null {} other {{days}d}} {hours, select, null {} other {{hours}h}} {minutes, select, null {} other {{minutes}m}} {seconds, select, null {} other {{seconds}s}} left"
                values={{ ...intlKeys }}
            />
        );

        switch (true) {
            case preview:
                timer = (
                    <FormattedMessage
                        id="CountDownTimer.liveInOutput"
                        defaultMessage="Live in {days, select, null {} other {{days}d}} {hours, select, null {} other {{hours}h}} {minutes, select, null {} other {{minutes}m}} {seconds, select, null {} other {{seconds}s}}"
                        values={{ ...intlKeys }}
                    />
                );
                break;
            case countdownText:
                timer = (
                    <FormattedMessage
                        id="CountDownTimer.countdownText"
                        defaultMessage="{days, select, null {} other {{days}d}} {hours, select, null {} other {{hours}h}} {minutes, select, null {} other {{minutes}m}} {seconds, select, null {} other {{seconds}s}}"
                        values={{ ...intlKeys }}
                    />
                );
                break;
        }

        return (
            <span
                className={classnames(
                    {
                        [styles.warnEnding]: withinThreshold,
                    },
                    className
                )}
                data-tn="countdown-timer"
            >
                {timer}
            </span>
        );
    }

    return (
        <dl
            className={classnames(styles.unitList, className, {
                [styles.rightLabels]: isRightAlignedLabel,
            })}
        >
            {unitsToShow.map(unitLabel => (
                <div className={styles.unitWrapper} key={unitLabel}>
                    <dt className={styles.unitLabel}>
                        {isRightAlignedLabel ? unitLabel[0] : unitLabel}
                    </dt>
                    <dd className={styles.unitAmount}>{countdownUnits[unitLabel]}</dd>
                </div>
            ))}
        </dl>
    );
};
