import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FunctionComponent } from 'react';

type LocationPinFilledProps = {
    className?: string;
};

const LocationPinFilled: FunctionComponent<LocationPinFilledProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'location-pin-filled'}
            viewBox="0 0 250 250"
        >
            <path d="M186 29.1C168.7 10.6 146.7.4 124.1.4 100.3.4 76.4 12 58.6 32c-20.4 23-29.9 53.8-26.3 84.7 3.2 26.9 19.1 57.4 47.1 90.6 20.4 24.1 41.1 42 42.8 42.7.6.3 1.2.4 1.9.4.9 0 1.7-.3 2.5-.8l.3-.3c.9-.7 21.3-17.5 42.7-42.2 28.9-33.5 44.5-64.1 46.3-90.8 2.3-32.7-8.6-64.5-29.9-87.2zm-62.1 113.1c-22 0-39.9-17.9-39.9-39.9s17.9-39.9 39.9-39.9 39.9 17.9 39.9 39.9-17.9 39.9-39.9 39.9z" />
        </svg>
    );
};
export default LocationPinFilled;
