import { useFragment, graphql } from 'react-relay';

import { AUCTIONS_LOT_STATUS } from '../constants';

import { useAuctionSecondChanceInfo_item$key } from './__generated__/useAuctionSecondChanceInfo_item.graphql';

const itemFragment = graphql`
    fragment useAuctionSecondChanceInfo_item on Item
    @argumentDefinitions(page: { type: "PageDisplayEnum" }) {
        isAuctionEnabled
        relevantAuctionLot(page: $page) {
            status
            startDate
            endDate
        }
        auctionSecondChanceInfo {
            offerSent
        }
    }
`;

type SecondChanceInfo = {
    isSecondChanceOfferSent: boolean;
};

const defaultReturn = {
    isSecondChanceOfferSent: false,
};

export const useAuctionSecondChanceInfo = ({
    item: itemRef,
}: {
    item: useAuctionSecondChanceInfo_item$key | null | undefined;
}): SecondChanceInfo => {
    const currentDate = new Date();
    const item = useFragment(itemFragment, itemRef);
    const relevantAuctionLot = item?.relevantAuctionLot;
    const auctionStatus = relevantAuctionLot?.status;
    const startDate = new Date(relevantAuctionLot?.startDate || '');
    const endDate = new Date(relevantAuctionLot?.endDate || '');
    const isActiveAction =
        currentDate.getTime() >= startDate.getTime() &&
        currentDate.getTime() < endDate.getTime() &&
        auctionStatus === AUCTIONS_LOT_STATUS.OPEN;

    if (!item?.isAuctionEnabled) {
        return defaultReturn;
    }
    const isOfferSent = item?.auctionSecondChanceInfo?.offerSent;
    if (isOfferSent && !isActiveAction) {
        return { isSecondChanceOfferSent: true };
    }

    return defaultReturn;
};
