import { ComponentProps, FC } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import classnames from 'classnames';

import { Badge } from 'dibs-elements/exports/Badge';

import dibsCss from 'dibs-css';

export type BadgeBackgroundColor = ComponentProps<typeof Badge>['backgroundColor'];

type Props = React.HTMLAttributes<HTMLDivElement> & {
    backgroundColor?: BadgeBackgroundColor;
    isItemTileShorterHeight?: boolean;
};
export const NoReserve: FC<Props> = ({ backgroundColor, isItemTileShorterHeight, ...props }) => {
    return (
        <div
            {...props}
            className={classnames({
                [dibsCss.mbXxsmall]: isItemTileShorterHeight,
                [dibsCss.mbXsmall]: !isItemTileShorterHeight,
            })}
            data-tn="no-reserve"
        >
            <Badge type="pill" size="small-heavy" backgroundColor={backgroundColor}>
                <FormattedMessage id="dc.searchProductTile.noReserve" defaultMessage="No Reserve" />
            </Badge>
        </div>
    );
};
