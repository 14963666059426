/**
 * @generated SignedSource<<8b9e695e832d0b3c81c11bca1cc95b97>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OriginalListPriceLazy_item$data = {
  readonly " $fragmentSpreads": FragmentRefs<"OriginalListPrice_item">;
  readonly " $fragmentType": "OriginalListPriceLazy_item";
};
export type OriginalListPriceLazy_item$key = {
  readonly " $data"?: OriginalListPriceLazy_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"OriginalListPriceLazy_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": "pdp",
      "kind": "LocalArgument",
      "name": "page"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "OriginalListPriceLazy_item",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "page",
          "variableName": "page"
        }
      ],
      "kind": "FragmentSpread",
      "name": "OriginalListPrice_item"
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "759b15ff307a10eea3ecdf5bd9161044";

export default node;
