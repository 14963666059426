/**
 * @generated SignedSource<<bdd95bccaa55a3d7b6a09f1e28e4897c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "CHF" | "DKK" | "EUR" | "GBP" | "JPY" | "MXN" | "NOK" | "SEK" | "USD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useOriginalListPrice_item$data = {
  readonly originalDisplayPrice: ReadonlyArray<{
    readonly originalConvertedAmountList: ReadonlyArray<{
      readonly amount: number | null;
      readonly currency: Currency | null;
    } | null> | null;
  } | null> | null;
  readonly pricing: {
    readonly retailPrice: {
      readonly convertedAmountList: ReadonlyArray<{
        readonly amount: number | null;
        readonly currency: Currency | null;
      } | null> | null;
    } | null;
  } | null;
  readonly " $fragmentType": "useOriginalListPrice_item";
};
export type useOriginalListPrice_item$key = {
  readonly " $data"?: useOriginalListPrice_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"useOriginalListPrice_item">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": "pdp",
      "kind": "LocalArgument",
      "name": "page"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useOriginalListPrice_item",
  "selections": [
    {
      "alias": "originalDisplayPrice",
      "args": [
        {
          "kind": "Variable",
          "name": "page",
          "variableName": "page"
        }
      ],
      "concreteType": "ItemDisplayPriceType",
      "kind": "LinkedField",
      "name": "displayPrice",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ConvertedAmountList",
          "kind": "LinkedField",
          "name": "originalConvertedAmountList",
          "plural": true,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ItemPricing",
      "kind": "LinkedField",
      "name": "pricing",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FinancialAmount",
          "kind": "LinkedField",
          "name": "retailPrice",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ConvertedAmountList",
              "kind": "LinkedField",
              "name": "convertedAmountList",
              "plural": true,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};
})();

(node as any).hash = "0fcd3518474430ab4d0c79539b43ada2";

export default node;
