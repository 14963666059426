/**
 * @generated SignedSource<<96469c71e177462cb9d7695421671ff1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "CHF" | "DKK" | "EUR" | "GBP" | "JPY" | "MXN" | "NOK" | "SEK" | "USD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useAuctionBids_item$data = {
  readonly auctionActiveMaxBids?: ReadonlyArray<{
    readonly amount: {
      readonly convertedAmountList: ReadonlyArray<{
        readonly amount: number | null;
        readonly currency: Currency | null;
      } | null> | null;
    } | null;
    readonly userId: string | null;
  } | null> | null;
  readonly relevantAuctionSolution: {
    readonly highestBidderId: string | null;
    readonly reserveMet: boolean | null;
  } | null;
  readonly " $fragmentType": "useAuctionBids_item";
};
export type useAuctionBids_item$key = {
  readonly " $data"?: useAuctionBids_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"useAuctionBids_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "fetchUser"
    },
    {
      "defaultValue": "pdp",
      "kind": "LocalArgument",
      "name": "page"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useAuctionBids_item",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "page",
          "variableName": "page"
        }
      ],
      "concreteType": "AuctionSolution",
      "kind": "LinkedField",
      "name": "relevantAuctionSolution",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reserveMet",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "highestBidderId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "condition": "fetchUser",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MaxBidType",
          "kind": "LinkedField",
          "name": "auctionActiveMaxBids",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "userId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "FinancialAmount",
              "kind": "LinkedField",
              "name": "amount",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ConvertedAmountList",
                  "kind": "LinkedField",
                  "name": "convertedAmountList",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "amount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "currency",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "8af46de71641b3494bbf1796448badb0";

export default node;
