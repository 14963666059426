/**
 * @generated SignedSource<<f58086fc0929b281d82d50a571d6bd3d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OriginalListPrice_item$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useOriginalListPrice_item">;
  readonly " $fragmentType": "OriginalListPrice_item";
};
export type OriginalListPrice_item$key = {
  readonly " $data"?: OriginalListPrice_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"OriginalListPrice_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": "pdp",
      "kind": "LocalArgument",
      "name": "page"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "OriginalListPrice_item",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "page",
          "variableName": "page"
        }
      ],
      "kind": "FragmentSpread",
      "name": "useOriginalListPrice_item"
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "f75e5ac6981611a1925b960b98353444";

export default node;
