import { useFragment, graphql } from 'react-relay';

import { AuctionState } from './types/AuctionState';
import { AUCTIONS_LOT_STATUS } from '../constants';

import { useAuctionState_item$key } from './__generated__/useAuctionState_item.graphql';

const itemFragment = graphql`
    fragment useAuctionState_item on Item @argumentDefinitions(page: { type: "PageDisplayEnum" }) {
        isAuctionEnabled
        relevantAuctionLot(page: $page) {
            status
            startDate
            endDate
        }
        relevantAuctionSolution(page: $page) {
            reserveMet
        }
    }
`;

const getTimeLeft = ({
    startDate,
    endDate,
}: {
    startDate: Date;
    endDate: Date;
}): { days: number; hours: number; seconds: number } => {
    const seconds = Math.floor((endDate.getTime() - startDate.getTime()) / 1000);
    const hours = Math.floor(seconds / 60 / 60);
    const days = Math.floor(hours / 24);

    return { days, hours, seconds };
};

const defaultReturn = {
    state: 'UNKNOWN' as const,
    days: 0,
    hours: 0,
    seconds: 0,
    isPreviewAuction: false,
    isActiveAuction: false,
    isPausedAuction: false,
    isEndedAuction: false,
    isTransitionAuction: false,
    isUnavailableAuction: false,
    hasAuctionEndedWithBIN: false,
    hasAuctionEndedWithWinner: false,
    hasAuctionEndedWithNoWinner: false,
};

export const useAuctionState = ({
    item: itemRef,
}: {
    item: useAuctionState_item$key | null | undefined;
}): AuctionState => {
    const currentDate = new Date();
    const item = useFragment(itemFragment, itemRef);
    const relevantAuctionSolution = item?.relevantAuctionSolution;
    const relevantAuctionLot = item?.relevantAuctionLot;
    const auctionStatus = relevantAuctionLot?.status;
    const startDate = new Date(relevantAuctionLot?.startDate || '');
    const endDate = new Date(relevantAuctionLot?.endDate || '');
    const isActiveAction =
        currentDate.getTime() >= startDate.getTime() &&
        currentDate.getTime() < endDate.getTime() &&
        auctionStatus === AUCTIONS_LOT_STATUS.OPEN;

    if (!item?.isAuctionEnabled) {
        return defaultReturn;
    }
    if (auctionStatus === AUCTIONS_LOT_STATUS.PAUSED) {
        // PAUSED auctions will not have endDate. Need to check before date checks
        return {
            ...defaultReturn,
            state: 'PAUSED',
            isPausedAuction: true,
        };
    } else if (auctionStatus === AUCTIONS_LOT_STATUS.CLOSED && !relevantAuctionLot?.endDate) {
        // BIN CLOSED items will have no endDate. Need to check before date checks
        return {
            ...defaultReturn,
            state: 'ENDED',
            isEndedAuction: true,
            hasAuctionEndedWithBIN: true,
        };
    } else if (!relevantAuctionLot?.startDate || !relevantAuctionLot?.endDate) {
        return defaultReturn;
    }

    if (currentDate.getTime() < startDate.getTime() && auctionStatus === AUCTIONS_LOT_STATUS.OPEN) {
        //only OPEN auctions can be in preview state
        return {
            state: 'PREVIEW',
            ...getTimeLeft({
                startDate: currentDate,
                endDate: startDate,
            }),
            isPreviewAuction: true,
        };
    } else if (isActiveAction) {
        //only OPEN auctions can be in active state
        return {
            state: 'ACTIVE',
            ...getTimeLeft({
                startDate: currentDate,
                endDate,
            }),
            isActiveAuction: true,
        };
    } else if (currentDate.getTime() >= endDate.getTime() && auctionStatus === 'OPEN') {
        return {
            ...defaultReturn,
            state: 'TRANSITION',
            isTransitionAuction: true,
        };
    } else if (
        auctionStatus === AUCTIONS_LOT_STATUS.CANCELED ||
        auctionStatus === AUCTIONS_LOT_STATUS.DELETED
    ) {
        return {
            ...defaultReturn,
            state: 'UNAVAILABLE',
            isUnavailableAuction: true,
        };
    } else if (auctionStatus === AUCTIONS_LOT_STATUS.CLOSED) {
        return {
            ...defaultReturn,
            state: 'ENDED',
            isEndedAuction: true,
            hasAuctionEndedWithWinner: relevantAuctionSolution?.reserveMet,
            hasAuctionEndedWithNoWinner: !relevantAuctionSolution?.reserveMet,
        };
    }

    return defaultReturn;
};
