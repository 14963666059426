/**
 * @generated SignedSource<<95982010c514a9d4fe92200cd6d393a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AuctionBids_item$data = {
  readonly isAuctionEnabled: boolean | null;
  readonly relevantAuctionSolution: {
    readonly bids: {
      readonly totalResults: number | null;
    } | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"useAuctionState_item">;
  readonly " $fragmentType": "AuctionBids_item";
};
export type AuctionBids_item$key = {
  readonly " $data"?: AuctionBids_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"AuctionBids_item">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "page"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AuctionBids_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAuctionEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "AuctionSolution",
      "kind": "LinkedField",
      "name": "relevantAuctionSolution",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AuctionBidsConnection",
          "kind": "LinkedField",
          "name": "bids",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalResults",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "useAuctionState_item"
    }
  ],
  "type": "Item",
  "abstractKey": null
};
})();

(node as any).hash = "c7c80f706b859d1d02aa8803775a6573";

export default node;
