/**
 * @generated SignedSource<<6d589e6050f57b69569f8abbe429f79d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AuctionCountdown_item$data = {
  readonly relevantAuctionLot: {
    readonly endDate: string | null;
    readonly startDate: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"useAuctionState_item">;
  readonly " $fragmentType": "AuctionCountdown_item";
};
export type AuctionCountdown_item$key = {
  readonly " $data"?: AuctionCountdown_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"AuctionCountdown_item">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "page"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AuctionCountdown_item",
  "selections": [
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "AuctionLot",
      "kind": "LinkedField",
      "name": "relevantAuctionLot",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "useAuctionState_item"
    }
  ],
  "type": "Item",
  "abstractKey": null
};
})();

(node as any).hash = "66f7c6ab9acd01a968281872fc797487";

export default node;
