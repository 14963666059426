import { FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import { FormattedMessage } from 'dibs-react-intl';
import { useAuctionState } from 'dibs-auctions/exports/hooks/useAuctionState';

import { AuctionBids_item$key } from './__generated__/AuctionBids_item.graphql';

import styles from './styles.scss';

const itemFragment = graphql`
    fragment AuctionBids_item on Item @argumentDefinitions(page: { type: "PageDisplayEnum" }) {
        isAuctionEnabled
        relevantAuctionSolution(page: $page) {
            bids {
                totalResults
            }
        }
        ...useAuctionState_item @arguments(page: $page)
    }
`;

export const AuctionBids: FC<{
    item: AuctionBids_item$key;
    isMobile?: boolean;
    showAuctionBids?: boolean;
}> = ({ item: itemRef, isMobile = false, showAuctionBids }) => {
    const item = useFragment(itemFragment, itemRef);
    const { isPreviewAuction } = useAuctionState({ item });
    const totalResults = item?.relevantAuctionSolution?.bids?.totalResults;
    /**
     * By default show auction bids only if isMobile is set to false. In some cases, we want to
     * show bids when isMobile is set to true. In that case, we want showAuctionBids to override.
     */
    const hideBids = typeof showAuctionBids === 'undefined' ? isMobile : !showAuctionBids;

    //don't show bids count if totalResults is nullish
    if (!item?.isAuctionEnabled || !totalResults || hideBids || isPreviewAuction) {
        return null;
    }

    return (
        <div className={styles.wrapper} data-tn="bid-count">
            <FormattedMessage
                values={{ totalResults }}
                id="dc.searchProductTile.bidsCount"
                defaultMessage="{totalResults, select, 1 { {totalResults} bid} other { {totalResults} bids}}"
            />
        </div>
    );
};
