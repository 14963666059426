/**
 * @generated SignedSource<<a257c67f63f61ee8529119bc6e854cfe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ItemTile_item$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ItemTileBase_item" | "Product_item">;
  readonly " $fragmentType": "ItemTile_item";
};
export type ItemTile_item$key = {
  readonly " $data"?: ItemTile_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"ItemTile_item">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "pageDisplayEnum",
  "variableName": "pageDisplayEnum"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "buyerId"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "fetchLiveShipmentQuote"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "fetchShippingCostInfo"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isTrade"
    },
    {
      "defaultValue": "searchAndBrowse",
      "kind": "LocalArgument",
      "name": "pageDisplayEnum"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "priceBookName"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "showAuctionCountdown"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showSeller"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "showShippingBadge"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ItemTile_item",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "buyerId",
          "variableName": "buyerId"
        },
        {
          "kind": "Variable",
          "name": "fetchLiveShipmentQuote",
          "variableName": "fetchLiveShipmentQuote"
        },
        {
          "kind": "Variable",
          "name": "fetchShippingCostInfo",
          "variableName": "fetchShippingCostInfo"
        },
        {
          "kind": "Variable",
          "name": "isTrade",
          "variableName": "isTrade"
        },
        (v0/*: any*/),
        {
          "kind": "Variable",
          "name": "priceBookName",
          "variableName": "priceBookName"
        },
        {
          "kind": "Variable",
          "name": "showSeller",
          "variableName": "showSeller"
        },
        {
          "kind": "Variable",
          "name": "showShippingBadge",
          "variableName": "showShippingBadge"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ItemTileBase_item"
    },
    {
      "args": [
        (v0/*: any*/),
        {
          "kind": "Variable",
          "name": "showAuctionCountdown",
          "variableName": "showAuctionCountdown"
        }
      ],
      "kind": "FragmentSpread",
      "name": "Product_item"
    }
  ],
  "type": "Item",
  "abstractKey": null
};
})();

(node as any).hash = "2232c45b22de8bf4766f897cb8c3b178";

export default node;
