import { FC } from 'react';
import { graphql, useFragment } from 'react-relay';
import { FormattedMessage } from 'dibs-react-intl';
import classnames from 'classnames';

import LocationPinFilled from 'dibs-icons/exports/legacy/LocationPinFilled';

import { ItemLocation_item$key } from './__generated__/ItemLocation_item.graphql';

import dibsCss from 'dibs-css';

type Props = {
    item: ItemLocation_item$key;
    isItemTileShorterHeight?: boolean;
};

export const ItemLocation: FC<Props> = ({ item: itemRef, isItemTileShorterHeight }) => {
    const item = useFragment(
        graphql`
            fragment ItemLocation_item on Item {
                isInEurope: isInRegion(regionName: "Europe")
                address {
                    displayCountry
                }
            }
        `,
        itemRef
    );

    if (!item) {
        return null;
    }

    const displayCountryOrRegion = item?.isInEurope ? (
        <FormattedMessage defaultMessage="Europe" id="item.location.europe" />
    ) : (
        item?.address?.displayCountry
    );
    return (
        <div
            className={classnames(dibsCss.flex, dibsCss.h14px, dibsCss.py1px, dibsCss.itemsCenter, {
                [dibsCss.mbXxsmall]: isItemTileShorterHeight,
                [dibsCss.mbXsmall]: !isItemTileShorterHeight,
            })}
        >
            <LocationPinFilled />
            <div
                className={classnames(dibsCss.sassyFontBodySizeXSmallTypeNormal, dibsCss.mlXxsmall)}
            >
                {displayCountryOrRegion}
            </div>
        </div>
    );
};
