import { useFragment, graphql } from 'react-relay';
import { getAmountInCurrency } from '../helpers/getAmountInCurrency';

import {
    useAuctionBids_item$key as Item,
    useAuctionBids_item$data,
} from './__generated__/useAuctionBids_item.graphql';

const itemFragment = graphql`
    fragment useAuctionBids_item on Item
    @argumentDefinitions(
        fetchUser: { type: "Boolean", defaultValue: false }
        page: { type: "PageDisplayEnum", defaultValue: pdp }
    ) {
        relevantAuctionSolution(page: $page) {
            reserveMet
            highestBidderId
        }
        auctionActiveMaxBids @include(if: $fetchUser) {
            userId
            amount {
                convertedAmountList {
                    amount
                    currency
                }
            }
        }
    }
`;

type ActiveBidsConvertedAmountList = NonNullable<
    NonNullable<
        NonNullable<NonNullable<useAuctionBids_item$data['auctionActiveMaxBids']>[0]>['amount']
    >['convertedAmountList']
>;

type ConvertedAmount = NonNullable<ActiveBidsConvertedAmountList[0]>['amount'];

export type ReturnType = {
    hasBid: boolean;
    hasHighestBid: boolean;
    highestBidAmount: ConvertedAmount;
    hasHighestBidAndReserveMet: boolean;
};

export const useAuctionBids = ({
    userId,
    currency = 'USD',
    item: itemRef,
}: {
    currency?: string;
    item: Item | null | undefined;
    userId?: string | null;
}): ReturnType => {
    const item = useFragment(itemFragment, itemRef);
    if (!userId) {
        return {
            hasBid: false,
            hasHighestBid: false,
            highestBidAmount: null,
            hasHighestBidAndReserveMet: false,
        };
    }
    const { relevantAuctionSolution, auctionActiveMaxBids } = item || {};
    const { highestBidderId, reserveMet } = relevantAuctionSolution || {};
    const highestBid = [...(auctionActiveMaxBids || [])]
        .reverse()
        .find(bid => bid?.userId === userId);

    return {
        hasBid: !!highestBid,
        hasHighestBid: highestBidderId === userId,
        hasHighestBidAndReserveMet: !!reserveMet && highestBidderId === userId,
        highestBidAmount: getAmountInCurrency(
            highestBid?.amount?.convertedAmountList || [],
            currency
        ),
    };
};
