import { useRef, useState, FC, ReactNode } from 'react';
import { useFragment, graphql } from 'react-relay';
import classnames from 'classnames';
import { FormattedMessage } from 'dibs-react-intl';
import { SwatchSelectorMinimal } from 'dibs-swatch-selector/exports/SwatchSelectorMinimal';
import { TileContextProvider } from '../helpers/TileContext';
import { Price } from '../Price';
import { ProductTileMeasurements } from './ProductTileMeasurements';
import { QuickViewButton } from '../QuickViewButton';
import { VisibilityTracker } from 'dibs-visibility-tracker/exports/VisibilityTracker';
import { Shipping } from '../Badges/Shipping';
import { BadgeBackgroundColor, NoReserve } from '../Badges/NoReserve';
import StorefrontOnlyBadge from '../Badges/StorefrontOnly';
import QuickViewItem from '../QuickViewItem';
import OfferedBy from '../Info/OfferedBy';
import InternalSellerName from '../Info/InternalSellerName';
import { CREATOR_UNKNOWN, DEFAULT_MEASUREMENT_UNIT } from '../constants';
import { SeoLink } from 'dibs-seo/exports/SeoLink';
import { SeoLink as SeoLinkElement } from 'dibs-elements/exports/SeoLink';
import HeadingLevel from 'dibs-controlled-heading/exports/HeadingLevel';
import { AuctionBids } from '../AuctionBids';
import { types } from '../helpers/priceTypeConstants';
import { AUCTIONS_LOT_STATUS } from 'dibs-auctions/src/constants';
import { trackEvent } from 'dibs-tracking';

import styles from './ItemTileBase.scss';
import dibsCss from 'dibs-css';

import { ItemTileBase_viewer$key } from './__generated__/ItemTileBase_viewer.graphql';
import { ItemTileBase_item$key } from './__generated__/ItemTileBase_item.graphql';
import { ItemTileBase_itemSearch$key } from './__generated__/ItemTileBase_itemSearch.graphql';
import { ImageSizeType } from '../types/StyleSizes';
import { ItemLocation } from '../Info/ItemLocation';
import useQuickView from '../QuickViewProvider/useQuickView';

const Sponsored: FC<{ needLeftPipe?: boolean }> = ({ needLeftPipe }) => {
    return (
        <div
            className={classnames(dibsCss.textSatan, dibsCss.mbXsmall, {
                [dibsCss.inline]: needLeftPipe,
                [dibsCss.mlXsmall]: needLeftPipe,
                [dibsCss.plXsmall]: needLeftPipe,
                [dibsCss.borderLSolid]: needLeftPipe,
                [dibsCss.borderL]: needLeftPipe,
                [dibsCss.borderLDolphin]: needLeftPipe,
            })}
        >
            <FormattedMessage id="dc.searchProductTile.sponsored" defaultMessage="Sponsored" />
        </div>
    );
};

const CREATOR_ATTRIBUTED_BY = 'BY';
const CREATOR_ATTRIBUTED_BY_AND_DOCUMENTED = 'BY_AND_DOCUMENTED';
const CREATOR_ATTRIBUTION_LIST = [CREATOR_ATTRIBUTED_BY, CREATOR_ATTRIBUTED_BY_AND_DOCUMENTED];
const TILE_TYPE_PROMINENT = 'PROMINENT_TILE';
const TILE_TYPE_IMG_LEFT_ALIGN = 'IMG_LEFT_ALIGN';

const viewerFragment = graphql`
    fragment ItemTileBase_viewer on Viewer
    @argumentDefinitions(
        fetchRegionalInfo: { type: "Boolean", defaultValue: false }
        userZipCode: { type: "String", defaultValue: "" }
        userCountryCode: { type: "String", defaultValue: "" }
    ) {
        ...Shipping_viewer
            @arguments(
                fetchRegionalInfo: $fetchRegionalInfo
                userZipCode: $userZipCode
                userCountryCode: $userCountryCode
            )
    }
`;
const itemSearchFragment = graphql`
    fragment ItemTileBase_itemSearch on ItemSearchQueryConnection {
        ...QuickViewItem_itemSearch
    }
`;
const itemFragment = graphql`
    fragment ItemTileBase_item on Item
    @argumentDefinitions(
        isTrade: { type: "Boolean", defaultValue: false }
        showSeller: { type: "Boolean!" }
        pageDisplayEnum: { type: "PageDisplayEnum", defaultValue: searchAndBrowse }
        buyerId: { type: "String", defaultValue: "" }
        fetchShippingCostInfo: { type: "Boolean", defaultValue: false }
        fetchLiveShipmentQuote: { type: "Boolean", defaultValue: true }
        showShippingBadge: { type: "Boolean", defaultValue: true }
        priceBookName: { type: "String" }
    ) {
        serviceId
        title
        pdpMeta {
            topQuery
        }
        localizedPdpUrl
        classification {
            creationDate
        }
        creators {
            attribution
            creator {
                displayName
            }
        }
        isArt
        seller @include(if: $showSeller) {
            serviceId
            sellerProfile {
                company
            }
        }
        linkData {
            ...SeoLink_linkData
        }
        relevantAuctionSolution(page: $pageDisplayEnum) {
            hasReserve
        }
        relevantAuctionLot(page: $pageDisplayEnum) {
            status
        }
        displayPrice(page: $pageDisplayEnum, isTrade: $isTrade, priceBookName: $priceBookName) {
            textType
        }
        isAuctionEnabled
        ...Shipping_item
            @include(if: $showShippingBadge)
            @arguments(
                buyerId: $buyerId
                fetchShippingCostInfo: $fetchShippingCostInfo
                fetchLiveShipmentQuote: $fetchLiveShipmentQuote
            )
        ...StorefrontOnly_item
        ...SwatchSelectorMinimal_item
        ...Price_item
            @arguments(
                isTrade: $isTrade
                pageDisplayEnum: $pageDisplayEnum
                priceBookName: $priceBookName
            )
        ...QuickViewItem_item
        ...ProductTileMeasurements_item
        ...AuctionBids_item @arguments(page: $pageDisplayEnum)
        ...ItemLocation_item
    }
`;

type SharedProductProps = {
    hideProductDetails: boolean;
    hideDivider: boolean;
    index: number;
    onClick?: () => void;
    title: string | null;
    topQuery: string;
    pdpUrl: string | null;
    itemsPerRow: number;
    onContentLoaded?: () => void;
    useLoFiLazyLoader?: boolean;
    imageLoadVerticalOffset?: number;
    hideImagePadding?: boolean;
    hideImageSidePadding?: boolean;
    srcSetSizes?: string;
    srcSetWidths?: string;
    srcSetQuality?: string;
    showLightBox?: boolean;
    showSwipeIndicator?: boolean;
    showAuctionCountdown?: boolean;
    centerImage: boolean;
    openInNewTab?: boolean;
    isHovering?: boolean;
    isMobile?: boolean;
    isQuickViewActive?: boolean;
    isItemTileShorterHeight?: boolean;
};

type Props = {
    item: ItemTileBase_item$key;
    viewer: ItemTileBase_viewer$key;
    itemSearch: ItemTileBase_itemSearch$key;
    index: number;
    imageSize?: ImageSizeType;
    currency?: string;
    showBrowseSimilar?: boolean;
    isMobile?: boolean;
    isSponsored?: boolean;
    showPrice?: boolean;
    showPriceContainer?: boolean;
    showMeasurements?: boolean;
    showMSKUSwatch?: boolean;
    showHoverState?: boolean;
    showSellerName?: boolean;
    priceBold?: boolean;
    measurementUnit?: string;
    itemsPerRow?: number;
    showQuickView?: boolean;
    useLoFiLazyLoader?: boolean;
    imageLoadVerticalOffset?: number;
    showQuickViewIcon?: boolean;
    showInternalSellerName?: boolean;
    showCreatorName?: boolean;
    showStorefrontOnlyBadge?: boolean;
    hideImagePadding?: boolean;
    hideImageSidePadding?: boolean;
    srcSetSizes?: string;
    srcSetWidths?: string;
    srcSetQuality?: string;
    favoritesComponent?: ReactNode;
    tileType?: string;
    onClick?: () => void;
    onContentLoaded?: () => void;
    onContentVisible?: () => void;
    onQuickViewDetailsClick?: () => void;
    children: (props: SharedProductProps) => ReactNode;
    showLightBox?: boolean;
    alignCenter?: boolean;
    disableBorder?: boolean;
    disableContentMargin?: boolean;
    showSwipeIndicator?: boolean;
    badgeBgColor?: BadgeBackgroundColor;
    showNoReserveBadge?: boolean;
    showAuctionBids?: boolean;
    openInNewTab?: boolean;
    displayOriginalListPrice?: boolean;
    showTitle?: boolean;
    showShippingBadge?: boolean;
    showItemLocationBadge?: boolean;
    showAuctionCountdown?: boolean;
    isItemTileShorterHeight?: boolean;
};

/*
    This can be renamed to Tile when work in VERTICALS-77 is completed.
    Prop isSbTile also can be removed then.
*/
export const ItemTileBase: FC<Props> = ({
    item: itemRef,
    index,
    imageSize = 'imageMedium',
    currency = 'USD',
    priceBold = false,
    showPrice = true,
    showPriceContainer = true, // shows priceContainer even price is not visible
    showBrowseSimilar,
    showMeasurements = true,
    showMSKUSwatch = true,
    showHoverState = false,
    showCreatorName,
    showQuickView = true,
    showQuickViewIcon = true,
    showInternalSellerName,
    showItemLocationBadge = false,
    showSellerName = false,
    showStorefrontOnlyBadge,
    showShippingBadge = true,
    showAuctionCountdown = true,
    isMobile,
    isSponsored,
    measurementUnit = DEFAULT_MEASUREMENT_UNIT,
    itemsPerRow = 3,
    onClick,
    onContentLoaded,
    onContentVisible,
    onQuickViewDetailsClick = () => {},
    useLoFiLazyLoader = false,
    imageLoadVerticalOffset,
    hideImagePadding,
    hideImageSidePadding,
    srcSetSizes,
    srcSetWidths,
    srcSetQuality,
    favoritesComponent,
    viewer: viewerRef = null,
    itemSearch: itemSearchRef = null,
    tileType,
    children,
    showLightBox = false,
    alignCenter = false,
    disableBorder = false,
    disableContentMargin = false,
    showSwipeIndicator = false,
    badgeBgColor,
    showNoReserveBadge = true,
    showAuctionBids,
    displayOriginalListPrice = false,
    showTitle = true,
    isItemTileShorterHeight = false,
}) => {
    const item = useFragment(itemFragment, itemRef);
    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);
    const viewer = useFragment(viewerFragment, viewerRef);
    const tileRef = useRef(null);
    const [isHovering, setIsHovering] = useState(false);
    const { activeQuickView, openQuickView } = useQuickView();

    const {
        title,
        localizedPdpUrl,
        isArt,
        serviceId: itemId,
        creators,
        classification,
        seller,
    } = item || {};

    const similarItemsHref = `/similar/?item=${itemId}`;
    const attribution = (creators || []).find(
        el =>
            typeof el?.attribution === 'string' && CREATOR_ATTRIBUTION_LIST.includes(el.attribution)
    );
    const attributionCreatorName = attribution?.creator?.displayName;
    const creationDate = classification?.creationDate || null;
    const sellerName = seller?.sellerProfile?.company;
    const showTileFooter = !isMobile && !showLightBox && (showBrowseSimilar || showMeasurements);
    const shouldShowInternalSellerName = showInternalSellerName && !!sellerName;
    const shouldShowSellerName = !showCreatorName && showSellerName && !!sellerName;
    const isQuickViewActive = index === activeQuickView;
    const isProminentTile = tileType === TILE_TYPE_PROMINENT;
    const linkData = item?.linkData;

    const hasReserve = !!item?.relevantAuctionSolution?.hasReserve;
    const isAuctionLotOpen = item?.relevantAuctionLot?.status === AUCTIONS_LOT_STATUS.OPEN;
    const isSoldDuringAuction =
        item?.isAuctionEnabled && item?.displayPrice?.[0]?.textType === types.SOLD;
    const renderNoReserveBadge =
        !hasReserve && showNoReserveBadge && (isAuctionLotOpen || isSoldDuringAuction);

    const containerClassNames = classnames(styles.container, {
        [styles.activeHover]: showHoverState || isQuickViewActive,
        [styles.isProminentTile]: isProminentTile,
        [styles.disableBorder]: showLightBox || (disableBorder && !isQuickViewActive),
        [styles.alignCenter]: alignCenter,
    });
    const footerClassNames = classnames(styles.footer, {
        [styles.activeHover]: showHoverState,
        [styles.isProminentTile]: isProminentTile,
    });
    const contentClassNames = classnames(styles.content, {
        [styles.isProminentTile]: isProminentTile,
        [styles.disableContentMargin]: disableContentMargin,
        [styles.lightBoxLayout]: showLightBox,
    });
    const openQuickViewFunc = (): void => {
        openQuickView?.(index);
        trackEvent({
            category: 'product',
            action: 'item tile info icon click',
        });
    };

    const showAttributionCreatorName =
        !!attributionCreatorName && attributionCreatorName.toLowerCase() !== CREATOR_UNKNOWN;

    const topQuery =
        item?.pdpMeta?.topQuery ||
        `${
            item?.isArt && showAttributionCreatorName ? `${attributionCreatorName} - ` : ''
        }${title}`;

    const sharedProductProps: SharedProductProps = {
        hideProductDetails: true,
        hideDivider: true,
        index,
        onClick,
        title,
        topQuery,
        pdpUrl: localizedPdpUrl,
        itemsPerRow,
        onContentLoaded,
        useLoFiLazyLoader,
        imageLoadVerticalOffset,
        hideImagePadding,
        hideImageSidePadding,
        srcSetSizes,
        srcSetWidths,
        srcSetQuality,
        showLightBox,
        showSwipeIndicator,
        showAuctionCountdown,
        centerImage: tileType !== TILE_TYPE_IMG_LEFT_ALIGN,
        isItemTileShorterHeight,
    };

    const dataTn = `item-tile-title-anchor${isSponsored ? '-sponsored' : ''}`;

    return (
        <TileContextProvider imageSize={imageSize}>
            <div
                className={containerClassNames}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
            >
                {shouldShowInternalSellerName && (
                    <InternalSellerName isSbTile sellerName={sellerName} />
                )}
                <QuickViewItem
                    isSbTile
                    item={item}
                    itemSearch={itemSearch}
                    isMobile={isMobile}
                    isQuickViewActive={isQuickViewActive}
                    onViewDetailsClick={onQuickViewDetailsClick}
                    focusOnActive
                />

                <div
                    className={contentClassNames}
                    // make tile inactive and blank when QuickViewItem overlay is active.
                    // visibility: hidden elements still take up space, which is desired in
                    // this instance bc QuickViewItem height depends on this div's height
                    style={isQuickViewActive ? { visibility: 'hidden' } : {}}
                >
                    {/* nest favoritesComponent inside of content div so it is also hidden when QuickViewItem is active */}
                    {favoritesComponent}
                    <div className={styles.productContainer} ref={tileRef}>
                        {onContentVisible && (
                            <VisibilityTracker
                                elementRef={tileRef}
                                onVisibilityChange={({ isVisible }) => {
                                    if (isVisible) {
                                        onContentVisible();
                                    }
                                }}
                                observerOptions={{ threshold: 0.5 }}
                            />
                        )}

                        {!!children &&
                            children({
                                ...sharedProductProps,
                                isHovering,
                                isMobile,
                                isQuickViewActive,
                            })}
                        {showQuickView && showLightBox && (
                            <div className={styles.quickViewLightBox}>
                                <QuickViewButton
                                    isSbTile
                                    openQuickView={openQuickViewFunc}
                                    showQuickViewIcon={showQuickViewIcon}
                                    hideDivider
                                />
                            </div>
                        )}
                    </div>
                    <div
                        className={classnames({
                            [styles.info]: !showLightBox && !isItemTileShorterHeight,
                            [styles.infoLightBox]: showLightBox,
                        })}
                    >
                        {isMobile && showMeasurements && (
                            <div
                                data-tn="product-tile-measurements"
                                className={classnames(styles.measurementsWrapper, styles.ellipsis)}
                            >
                                <ProductTileMeasurements
                                    item={item}
                                    measurementUnit={measurementUnit}
                                    isMobile={isMobile}
                                />
                            </div>
                        )}

                        <div className={styles.titleWrapper}>
                            {showTitle && (
                                <HeadingLevel>
                                    {Heading => (
                                        <SeoLink
                                            dataTn={dataTn}
                                            // data-pk attribute is used by dibs-toho (MG)
                                            dataPk={itemId || ''}
                                            onClick={onClick}
                                            className={classnames(
                                                styles.title,
                                                styles.tileLink,
                                                styles.titleLink
                                            )}
                                            linkData={linkData}
                                        >
                                            <Heading className={styles.titleHeader}>
                                                {isArt ? (
                                                    <>
                                                        {showAttributionCreatorName && (
                                                            <div
                                                                className={classnames(
                                                                    styles.ellipsis,
                                                                    styles.creator
                                                                )}
                                                                data-tn="portfolio-row-creator-name"
                                                            >
                                                                {attributionCreatorName}
                                                            </div>
                                                        )}
                                                        <div
                                                            className={classnames(styles.artTitle, {
                                                                [styles.artTitleNoCreator]:
                                                                    !showAttributionCreatorName,
                                                            })}
                                                        >
                                                            <span
                                                                className={styles.ellipsis}
                                                                data-tn="portfolio-row-product-name"
                                                            >
                                                                {title}
                                                            </span>
                                                            {creationDate && `, ${creationDate}`}
                                                        </div>
                                                    </>
                                                ) : (
                                                    title
                                                )}
                                            </Heading>
                                        </SeoLink>
                                    )}
                                </HeadingLevel>
                            )}
                            {showQuickView && !showLightBox && (
                                <QuickViewButton
                                    isSbTile
                                    openQuickView={openQuickViewFunc}
                                    showQuickViewIcon={showQuickViewIcon}
                                    hideDivider
                                />
                            )}
                        </div>

                        {showPriceContainer ? (
                            <div className={styles.priceBidsWrapper}>
                                {showPrice ? (
                                    <Price
                                        isSbTile
                                        currency={currency}
                                        item={item}
                                        priceBold={priceBold}
                                        displayOriginalListPrice={displayOriginalListPrice}
                                        isItemTileShorterHeight={isItemTileShorterHeight}
                                    />
                                ) : (
                                    <div className={styles.pricePlaceHolder} /> // placeholder to avoid flicker
                                )}
                                <AuctionBids
                                    item={item}
                                    isMobile={isMobile}
                                    showAuctionBids={showAuctionBids}
                                />
                            </div>
                        ) : null}
                        {renderNoReserveBadge ? (
                            <NoReserve
                                backgroundColor={badgeBgColor}
                                isItemTileShorterHeight={isItemTileShorterHeight}
                            />
                        ) : (
                            showShippingBadge && (
                                <Shipping
                                    item={item}
                                    viewer={viewer}
                                    currency={currency}
                                    backgroundColor={badgeBgColor}
                                    isItemTileShorterHeight={isItemTileShorterHeight}
                                />
                            )
                        )}
                        {/* show itemLocation only if item price is visible */}
                        {showPrice && showItemLocationBadge && (
                            <ItemLocation
                                item={item}
                                isItemTileShorterHeight={isItemTileShorterHeight}
                            />
                        )}
                        {showStorefrontOnlyBadge && (
                            <StorefrontOnlyBadge
                                item={item}
                                isItemTileShorterHeight={isItemTileShorterHeight}
                            />
                        )}
                        {showMSKUSwatch && (
                            <SwatchSelectorMinimal
                                displayCount={isMobile ? 3 : 4}
                                item={item}
                                path={localizedPdpUrl}
                                onClick={onClick}
                            />
                        )}
                        {shouldShowSellerName && (
                            <OfferedBy
                                className={classnames(
                                    dibsCss.sassyFontBodyXSmall,
                                    dibsCss.block,
                                    dibsCss.whitespaceNormal,
                                    dibsCss.textSatan,
                                    {
                                        [dibsCss.pbXxsmall]:
                                            showTileFooter &&
                                            showMeasurements &&
                                            isItemTileShorterHeight,
                                        [dibsCss.pbXsmall]:
                                            showTileFooter &&
                                            showMeasurements &&
                                            !isItemTileShorterHeight,
                                        [dibsCss.pb3px]: !(showTileFooter && showMeasurements),
                                        [dibsCss.mrMedium]: showQuickView,
                                        [dibsCss.mrSmall]: !showQuickView,
                                    }
                                )}
                                sellerName={sellerName}
                                message={isSponsored ? <Sponsored needLeftPipe /> : null}
                            />
                        )}
                        {isSponsored && !shouldShowSellerName && <Sponsored />}
                        {showTileFooter && (
                            <div className={footerClassNames}>
                                <div data-tn="product-tile-measurements">
                                    {showMeasurements && (
                                        <ProductTileMeasurements
                                            item={item}
                                            measurementUnit={measurementUnit}
                                        />
                                    )}
                                </div>
                                {showBrowseSimilar && (
                                    <FormattedMessage
                                        id="dc.searchProductTile.browseSimilar"
                                        defaultMessage="Browse Similar"
                                    >
                                        {msg => (
                                            <SeoLinkElement
                                                dataTn="browse-similar"
                                                linkData={{
                                                    path: similarItemsHref,
                                                    isLinkable: false,
                                                }}
                                                onClick={() =>
                                                    trackEvent({
                                                        category: 'product',
                                                        action: 'item tile browse similar click',
                                                    })
                                                }
                                            >
                                                {msg}
                                            </SeoLinkElement>
                                        )}
                                    </FormattedMessage>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </TileContextProvider>
    );
};
