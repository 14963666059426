/**
 * @generated SignedSource<<8de351de8400db2381f1a119774b35ed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AuctionLotStatus = "CANCELED" | "CLOSED" | "DELETED" | "DRAFT" | "OPEN" | "PAUSED" | "SCHEDULED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useAuctionSecondChanceInfo_item$data = {
  readonly auctionSecondChanceInfo: {
    readonly offerSent: boolean | null;
  } | null;
  readonly isAuctionEnabled: boolean | null;
  readonly relevantAuctionLot: {
    readonly endDate: string | null;
    readonly startDate: string | null;
    readonly status: AuctionLotStatus | null;
  } | null;
  readonly " $fragmentType": "useAuctionSecondChanceInfo_item";
};
export type useAuctionSecondChanceInfo_item$key = {
  readonly " $data"?: useAuctionSecondChanceInfo_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"useAuctionSecondChanceInfo_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "page"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useAuctionSecondChanceInfo_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAuctionEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "page",
          "variableName": "page"
        }
      ],
      "concreteType": "AuctionLot",
      "kind": "LinkedField",
      "name": "relevantAuctionLot",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AuctionSecondChanceInfo",
      "kind": "LinkedField",
      "name": "auctionSecondChanceInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "offerSent",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "c122682236c867319cb083901e11816c";

export default node;
