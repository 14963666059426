import { useFragment, graphql } from 'react-relay';
import { getAmountInCurrency } from '../helpers/getAmountInCurrency';

import type { ConvertedAmount } from './types/ConvertedAmount';
import type { useEligibleSecondChanceOfferState_item$key as Item } from './__generated__/useEligibleSecondChanceOfferState_item.graphql';

const itemFragment = graphql`
    fragment useEligibleSecondChanceOfferState_item on Item {
        auctionSecondChanceInfo {
            eligibleAuctionLot {
                buyItNowPrice {
                    convertedAmountList {
                        amount
                        currency
                    }
                }
                reservePrice {
                    convertedAmountList {
                        amount
                        currency
                    }
                }
            }
            eligibleAuctionSolution {
                reserveMet
                totalUniqueBidders
                bids {
                    totalResults
                    edges {
                        node {
                            amount {
                                convertedAmountList {
                                    amount
                                    currency
                                }
                            }
                        }
                    }
                }
                highestBidAmount {
                    convertedAmountList {
                        amount
                        currency
                    }
                }
            }
        }
    }
`;

export type ReturnType = {
    totalUniqueBidders: number;
    secondHighestBidAmount?: ConvertedAmount;
    buyItNowAmount: ConvertedAmount;
    highestBidAmount: ConvertedAmount;
    reservePriceAmount: ConvertedAmount;
    reserveMet: boolean;
};

export const useEligibleSecondChanceOfferState = ({
    currency,
    item: itemRef,
}: {
    item: Item | null | undefined;
    currency?: string;
}): ReturnType => {
    const item = useFragment(itemFragment, itemRef);
    const auctionSecondChanceInfo = item?.auctionSecondChanceInfo;
    const { eligibleAuctionSolution, eligibleAuctionLot } = auctionSecondChanceInfo || {};
    const { reserveMet, bids, highestBidAmount } = eligibleAuctionSolution || {};
    const { buyItNowPrice, reservePrice } = eligibleAuctionLot || {};
    const bidsAmount = bids?.edges;
    const totalBids = bids?.totalResults || 0;

    let totalUniqueBidders = eligibleAuctionSolution?.totalUniqueBidders || 0;
    let secondHighestBidAmount;
    // reserveMet and winning bid fell through get second highest bid
    if (reserveMet && totalBids > 1 && totalUniqueBidders > 1) {
        secondHighestBidAmount = getAmountInCurrency(
            bidsAmount?.[totalBids - 2]?.node?.amount?.convertedAmountList || [],
            currency
        );
        totalUniqueBidders -= 1;
    }

    return {
        totalUniqueBidders,
        secondHighestBidAmount,
        buyItNowAmount: getAmountInCurrency(buyItNowPrice?.convertedAmountList || [], currency),
        highestBidAmount: getAmountInCurrency(
            highestBidAmount?.convertedAmountList || [],
            currency
        ),
        reservePriceAmount: getAmountInCurrency(reservePrice?.convertedAmountList || [], currency),
        reserveMet: !!reserveMet,
    };
};
