/**
 * @generated SignedSource<<ffcc0786e7d6c0388c4282d18d9a9a63>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "CHF" | "DKK" | "EUR" | "GBP" | "JPY" | "MXN" | "NOK" | "SEK" | "USD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Price_item$data = {
  readonly displayPrice?: ReadonlyArray<{
    readonly amountType: string | null;
    readonly convertedAmountList: ReadonlyArray<{
      readonly amount: number | null;
      readonly currency: Currency | null;
    } | null> | null;
    readonly percentageOff: string | null;
    readonly quantityDisplay: string | null;
    readonly showPriceLabel: boolean | null;
    readonly showPriceVariability: boolean | null;
    readonly textType: string | null;
  } | null> | null;
  readonly isAuctionEnabled: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"OriginalListPrice_item" | "useAuctionState_item">;
  readonly " $fragmentType": "Price_item";
};
export type Price_item$key = {
  readonly " $data"?: Price_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"Price_item">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "page",
  "variableName": "pageDisplayEnum"
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isTrade"
    },
    {
      "defaultValue": "searchAndBrowse",
      "kind": "LocalArgument",
      "name": "pageDisplayEnum"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "priceBookName"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "showPrice"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Price_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAuctionEnabled",
      "storageKey": null
    },
    {
      "condition": "showPrice",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "isTrade",
              "variableName": "isTrade"
            },
            (v0/*: any*/),
            {
              "kind": "Variable",
              "name": "priceBookName",
              "variableName": "priceBookName"
            }
          ],
          "concreteType": "ItemDisplayPriceType",
          "kind": "LinkedField",
          "name": "displayPrice",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ConvertedAmountList",
              "kind": "LinkedField",
              "name": "convertedAmountList",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "amount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "currency",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "quantityDisplay",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "percentageOff",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "textType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amountType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showPriceLabel",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showPriceVariability",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "args": (v1/*: any*/),
      "kind": "FragmentSpread",
      "name": "OriginalListPrice_item"
    },
    {
      "args": (v1/*: any*/),
      "kind": "FragmentSpread",
      "name": "useAuctionState_item"
    }
  ],
  "type": "Item",
  "abstractKey": null
};
})();

(node as any).hash = "191a9b3584724a09772915108e2e4071";

export default node;
