import { useContext } from 'react';
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import classnames from 'classnames';
import { FormattedMessage } from 'dibs-react-intl';

// Components
import { Net } from './Net';
import { Sale } from './Sale';
import { Retail } from './Retail';
import { PricePaddleIcon } from './PricePaddleIcon';
import { OriginalListPrice } from 'dibs-auctions/exports/OriginalListPrice';

// Helpers
import { textPriceValue, types, isValidTextPriceType } from '../helpers/priceTypeConstants';
import { TileContext } from '../helpers/TileContext';
import { FontSizeType } from '../types/StyleSizes';
import { useAuctionState } from 'dibs-auctions/exports/hooks';
import { retailLabel, soldPriceLabel } from './messages';
import { findConvertedAmount } from './utils/findConvertedAmount';

import { Price_item$data as ItemType } from './__generated__/Price_item.graphql';

// Styles
import styles from './styles.scss';

type PriceProps = {
    currency: string;
    item: ItemType | null | undefined;
    hidePercentageOff?: boolean;
    priceBold?: boolean;
    isSbTile?: boolean;
    priceFontSize?: FontSizeType;
    displayOriginalListPrice?: boolean;
    isItemTileShorterHeight?: boolean;
};

export const PriceComponent: React.FC<PriceProps> = ({
    currency = 'USD',
    item,
    hidePercentageOff,
    priceBold,
    isSbTile = false,
    priceFontSize,
    displayOriginalListPrice,
    isItemTileShorterHeight = false,
}) => {
    const { imageSize = 'imageMedium', fontSize = 'fontSmall' } = useContext(TileContext);
    const { hasAuctionEndedWithNoWinner } = useAuctionState({ item });

    const isAuctionEnabled = !!item?.isAuctionEnabled;
    const displayPrice = item?.displayPrice?.[0];
    // Price display for SB page type always returns a single price type
    const {
        textType,
        amountType,
        convertedAmountList,
        quantityDisplay,
        percentageOff,
        showPriceLabel,
        showPriceVariability,
    } = displayPrice || {};
    const percentageValue = hidePercentageOff ? null : percentageOff;
    const amountByCurrency =
        findConvertedAmount({
            convertedAmountList: convertedAmountList || [],
            currency,
        })?.amount || 0;
    const price = amountByCurrency < 1 ? amountByCurrency : Math.floor(amountByCurrency); // AUCTIONS-1545 show cents if price is under 1 currency unit
    const originalListPriceComponent = displayOriginalListPrice ? (
        <OriginalListPrice
            currency={currency}
            item={item}
            isCompact
            className={styles.leftPipeDivider}
        />
    ) : null;

    const sharedProps = {
        currency,
        price,
        quantityDisplay,
        showPriceVariability,
        isSbTile,
    };

    const isValidTextPrice = isValidTextPriceType(textType);

    const textTypePriceClass = classnames(
        styles.price,
        styles[priceFontSize || fontSize],
        {
            [styles.alert]:
                textType === types.SOLD ||
                textType === types.HOLD ||
                textType === types.UNAVAILABLE,
            [styles.isSbTile]: isSbTile && !isItemTileShorterHeight,
            [styles.xxSmallYPadding]: isSbTile && isItemTileShorterHeight,
        },
        styles[imageSize]
    );

    const priceClass = classnames(
        styles.price,
        styles[priceFontSize || fontSize],
        {
            [styles.isSbTile]: isSbTile && !isItemTileShorterHeight,
            [styles.xxSmallYPadding]: isSbTile && isItemTileShorterHeight,
            [styles.priceBold]: priceBold,
            [styles.priceLineThrough]: isValidTextPrice,
            [styles.leftPipeDivider]: isValidTextPrice,
        },
        styles[imageSize]
    );

    if (isAuctionEnabled && hasAuctionEndedWithNoWinner) {
        return (
            <div className={priceClass} data-tn="price-auction-bidding-closed">
                <PricePaddleIcon />
                <FormattedMessage
                    id="dc.searchProductTile.biddingClosed"
                    defaultMessage="Bidding Closed"
                />
            </div>
        );
    }

    if (isValidTextPrice) {
        if (isAuctionEnabled) {
            const showPrice = price > 0 && textType !== types.UNAVAILABLE;
            return (
                <div data-tn={`price-auction-${textType}`} className={styles.textTypePriceWrapper}>
                    <div className={textTypePriceClass}>
                        <PricePaddleIcon />
                        {textPriceValue(textType)}
                    </div>
                    {showPrice && (
                        <div className={priceClass} data-tn="price-auction-retail">
                            <Retail {...sharedProps} />
                        </div>
                    )}
                </div>
            );
        }

        const showPrice = price > 0 && textType !== types.PUR;
        return (
            <div data-tn={`price-${textType}`} className={styles.textTypePriceWrapper}>
                <div className={textTypePriceClass}>{textPriceValue(textType)}</div>
                {showPrice && (
                    <div className={priceClass} data-tn="price-retail">
                        <Retail {...sharedProps} />
                    </div>
                )}
            </div>
        );
    }

    if (!price || !amountType) {
        return null;
    }

    if (amountType === types.SALE) {
        return (
            <div className={priceClass} data-tn="price-sale">
                <Sale {...sharedProps} percentageOff={percentageValue} />
            </div>
        );
    } else if (amountType === types.NET) {
        return (
            <div className={priceClass} data-tn="price-net">
                <Net {...sharedProps} percentageOff={percentageValue} />
            </div>
        );
    } else if (amountType === types.RETAIL) {
        if (isAuctionEnabled) {
            return (
                <div className={priceClass} data-tn="price-auction-retail">
                    <PricePaddleIcon />
                    <Retail {...sharedProps} />
                    {originalListPriceComponent}
                </div>
            );
        } else if (textType === types.SOLD_PRICE) {
            return (
                <div className={priceClass} data-tn="price-similar-sold">
                    <Retail {...sharedProps} label={soldPriceLabel} />
                </div>
            );
        }
        return (
            <div className={priceClass} data-tn="price-retail">
                <Retail {...sharedProps} label={showPriceLabel ? retailLabel : ''} />
            </div>
        );
    }

    return null;
};

export const Price = createFragmentContainer(PriceComponent, {
    item: graphql`
        fragment Price_item on Item
        @argumentDefinitions(
            isTrade: { type: "Boolean", defaultValue: false }
            pageDisplayEnum: { type: "PageDisplayEnum", defaultValue: searchAndBrowse }
            showPrice: { type: "Boolean", defaultValue: true }
            priceBookName: { type: "String" }
        ) {
            isAuctionEnabled
            displayPrice(page: $pageDisplayEnum, isTrade: $isTrade, priceBookName: $priceBookName)
                @include(if: $showPrice) {
                convertedAmountList {
                    amount
                    currency
                }
                quantityDisplay
                percentageOff
                textType
                amountType
                showPriceLabel
                showPriceVariability
            }
            ...OriginalListPrice_item @arguments(page: $pageDisplayEnum)
            ...useAuctionState_item @arguments(page: $pageDisplayEnum)
        }
    `,
});
