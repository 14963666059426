import { FC, ComponentProps } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { FormattedPrice } from 'dibs-buyer-price';
import { Badge } from 'dibs-elements/exports/Badge';
import { useShippingPrice } from '../../helpers/useShippingPrice';
import classnames from 'classnames';

import { Shipping_item$data as ItemProp } from './__generated__/Shipping_item.graphql';
import { Shipping_viewer$data as ViewerProp } from './__generated__/Shipping_viewer.graphql';

import dibsCss from 'dibs-css';

type BadgeBackgroundColor = ComponentProps<typeof Badge>['backgroundColor'];

type FreeShippingProps = React.HTMLAttributes<HTMLDivElement> & {
    backgroundColor?: BadgeBackgroundColor;
    isItemTileShorterHeight?: boolean;
};

export const FreeShippingBadge: FC<FreeShippingProps> = ({
    backgroundColor,
    isItemTileShorterHeight,
    ...props
}) => {
    return (
        <div
            {...props}
            className={classnames({
                [dibsCss.mbXxsmall]: isItemTileShorterHeight,
                [dibsCss.mbXsmall]: !isItemTileShorterHeight,
            })}
            data-tn="free-shipping"
        >
            <Badge type="pill" size="small-heavy" backgroundColor={backgroundColor}>
                <FormattedMessage
                    id="dc.searchProductTile.freeShipping"
                    defaultMessage="Free Shipping"
                />
            </Badge>
        </div>
    );
};

const ShippingComponent: FC<{
    viewer: ViewerProp | null | undefined;
    item: ItemProp | null;
    currency: string;
    backgroundColor?: BadgeBackgroundColor;
    isItemTileShorterHeight?: boolean;
}> = ({ item, viewer, currency, backgroundColor, isItemTileShorterHeight }) => {
    const amount = useShippingPrice({ item, viewer, currency });

    if (amount === null) {
        return null;
    }

    if (amount === 0) {
        return (
            <FreeShippingBadge
                backgroundColor={backgroundColor}
                isItemTileShorterHeight={isItemTileShorterHeight}
            />
        );
    }

    return (
        <div
            className={classnames(dibsCss.sassyFontBodySmall, {
                [dibsCss.mbXxsmall]: isItemTileShorterHeight,
                [dibsCss.mbXsmall]: !isItemTileShorterHeight,
            })}
            data-tn="shipping-cost"
        >
            <FormattedMessage
                id="dc.searchProductTile.shipping"
                defaultMessage="{price} shipping"
                values={{ price: <FormattedPrice currency={currency} value={amount} /> }}
            />
        </div>
    );
};

export const Shipping = createFragmentContainer(ShippingComponent, {
    viewer: graphql`
        fragment Shipping_viewer on Viewer
        @argumentDefinitions(
            fetchRegionalInfo: { type: "Boolean", defaultValue: false }
            userZipCode: { type: "String", defaultValue: "" }
            userCountryCode: { type: "String", defaultValue: "" }
        ) {
            ...useShippingPrice_viewer
                @arguments(
                    fetchRegionalInfo: $fetchRegionalInfo
                    userZipCode: $userZipCode
                    userCountryCode: $userCountryCode
                )
        }
    `,
    item: graphql`
        fragment Shipping_item on Item
        @argumentDefinitions(
            buyerId: { type: "String", defaultValue: "" }
            fetchShippingCostInfo: { type: "Boolean", defaultValue: false }
            fetchLiveShipmentQuote: { type: "Boolean", defaultValue: true }
            userZipCode: { type: "String", defaultValue: "" }
            userCountryCode: { type: "String", defaultValue: "" }
        ) {
            ...useShippingPrice_item
                @arguments(
                    buyerId: $buyerId
                    fetchShippingCostInfo: $fetchShippingCostInfo
                    fetchLiveShipmentQuote: $fetchLiveShipmentQuote
                    userZipCode: $userZipCode
                    userCountryCode: $userCountryCode
                )
        }
    `,
});
